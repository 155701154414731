import React, { useEffect, useRef } from "react";

const TradingViewWidget = ({ onSymbolChange }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/tv.js";
      script.async = true;
      script.onload = () => {
        new window.TradingView.widget({
          width: containerRef.current.clientWidth,
          popup_width: "1000",
          popup_height: "650",
          symbol: "BINANCE:BTCUSDT",
          interval: "240",
          timezone: "Etc/UTC",
          theme: "dark",
          style: "1",
          locale: "en",
          toolbar_bg: "#f1f3f6",
          enable_publishing: true,
          allow_symbol_change: true,
          container_id: "tradingview_6f3d7",
          autosize: true,
          withdateranges: false,
          //hide_side_toolbar: true,
          watchlist: [
            "BINANCE:BTCUSDT",
            "BINANCE:ETHUSDT",
            "BINANCE:BNBUSDT",
            "BINANCE:SOLUSDT",
            "BINANCE:ADAUSDT",
            "BINANCE:XRPUSDT",
            "BINANCE:DOGEUSDT",
            "BINANCE:MATICUSDT",
            "BINANCE:DOTUSDT",
            "BINANCE:UNIUSDT",
            "BINANCE:LTCUSDT",
            "BINANCE:LINKUSDT",
            "BINANCE:BCHUSDT",
            "BINANCE:AVAXUSDT",
            "BINANCE:TRXUSDT",
            "BINANCE:ETCUSDT",
            "BINANCE:FILUSDT",
            "BINANCE:XLMUSDT",
            "BINANCE:AAVEUSDT",
            "BINANCE:VETUSDT",
            "BINANCE:FTMUSDT",
            "BINANCE:THETAUSDT",
            "BINANCE:ICPUSDT",
            "BINANCE:ALGOUSDT",
            "BINANCE:EGLDUSDT",
            "BINANCE:HBARUSDT",
            "BINANCE:SANDUSDT",
            "BINANCE:MANAUSDT",
            "BINANCE:AXSUSDT",
            "BINANCE:KSMUSDT",
            "BINANCE:ZILUSDT",
            "BINANCE:ENJUSDT",
            "BINANCE:GRTUSDT",
            "BINANCE:CHZUSDT",
            "BINANCE:CRVUSDT",
            "BINANCE:COMPUSDT",
            "BINANCE:MKRUSDT",
            "BINANCE:SNXUSDT",
            "BINANCE:YFIUSDT",
            "BINANCE:1INCHUSDT",
            "BINANCE:RUNEUSDT",
            "BINANCE:ZRXUSDT",
            "BINANCE:LRCUSDT",
            "BINANCE:BALUSDT",
            "BINANCE:CVCUSDT",
            "BINANCE:SUSHIUSDT",
            "BINANCE:SKLUSDT",
            "BINANCE:RENUSDT",
            "BINANCE:KAVAUSDT",
            "BINANCE:STORJUSDT",
          ],
          details: true,
          hotlist: true,
          calendar: true,
          studies: ["STD;SMA"],
          show_popup_button: true,
          hide_side_toolbar: false,
          onSymbolChanged: onSymbolChange,
        });

        window.addEventListener("message", function (event) {
          try {
            var data = JSON.parse(event.data);
            if (
              data.provider === "TradingView" &&
              data.name === "quoteUpdate"
            ) {
              onSymbolChange(data.data.original_name);
            }
          } catch (err) {
            console.error("Error parsing message:", err);
          }
        });
      };

      document.body.appendChild(script);

      return () => {
        // Cleanup the script from the body when the component unmounts
        document.body.removeChild(script);
      };
    }
  }, [onSymbolChange]);

  return (
    <div
      id="tradingview_6f3d7"
      ref={containerRef}
      style={{ width: "100%", height: "600px" }}
    />
  );
};

export default TradingViewWidget;
