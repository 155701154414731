import React from "react";
import "./coins.css";
import CoinList from "./coin_list/coin_list";
import GSBbackground from "../../assets/images/coins_images/GCB_background.png";
import { useTranslation } from "react-i18next";
import PictureGuide from "../../components/picture_guide/guide.js";
import Touch from "../../components/touch/touch.js";
import InviteFriends from "../../components/invite_friends/invite_friends.js";
import Explore from "../../components/explore_guide/explore.js";
import TradeAnytime from "../../components/trade_anytime/trade_anytime.js";

const Coins = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="image-container container">
        <div className="overlay-text container_divs">
          <h1>{t("welcome_message")}</h1>
          <p>{t("welcome_message_desc")}</p>
        </div>
        <div className="container_divs">
          <img
            src={GSBbackground}
            alt="GSBbackground"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>
      <div className="container">
        <CoinList />
        <PictureGuide />
        <Touch />
        <InviteFriends />
        <Explore />
        <TradeAnytime />
      </div>
    </>
  );
};

export default Coins;
