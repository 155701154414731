import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Coin from "../coin_description/coin_description";
import CoinItem from "../coin_item/coin_item";
import { useTranslation } from "react-i18next";
import { getCoins } from "../../../redux/coins/coinsActions.js";
import { useDispatch, useSelector } from "react-redux";
import PictureCard from "../../../components/picture_card/picture_card.js";

const CoinList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [coinsData, setCoinsData] = useState([]);
  const { coins, coinsLoading } = useSelector((state) => state.coinsReducer);

  useEffect(() => {
    // Function to fetch coins
    const fetchCoins = () => dispatch(getCoins());

    // Fetch data immediately
    fetchCoins();

    // Set interval to fetch data every 15 seconds
    const intervalId = setInterval(fetchCoins, 15000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    if (coins.length > 0) {
      setCoinsData(coins);
    }
  }, [coins, coinsLoading]);

  const [currentPage, setCurrentPage] = useState(1);
  const coinsPerPage = 10;

  // Calculate the total number of pages
  const totalPages = Math.ceil(coinsData.length / coinsPerPage);

  // Calculate the coins to display
  const indexOfLastCoin = currentPage * coinsPerPage;
  const indexOfFirstCoin = indexOfLastCoin - coinsPerPage;
  const currentCoins = coinsData.slice(indexOfFirstCoin, indexOfLastCoin);

  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // Handle page click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <ul className="recommend-list_group">
        {coinsData.slice(0, 5).map((item, index) => (
          <li className="recommend-item" key={index}>
            <div className="symbol-detail">
              <span className="symbol-name">{item?.symbol.toUpperCase()}</span>
              <i class="symbol-change fall-1-cl">
                {item?.price_change_percentage_24h.toFixed(2)}%
              </i>
            </div>
            <div className="symbol-price">
              {item?.current_price.toLocaleString()}
            </div>
            <div className="symbol-otcPrice">
              ${item?.current_price.toFixed(2)}
            </div>
          </li>
        ))}
      </ul>
      <PictureCard />
      <div className="heading">
        <p>#</p>
        <p className="coin-name">{t("coin")}</p>
        <p>{t("price")}</p>
        <p>24h</p>
        <p className="hide-mobile">{t("volume")}</p>
        <p className="hide-mobile">{t("mt_cap")}</p>
      </div>
      {currentCoins.map((coin, index) => (
        <Link to={`/coin/${coin.id}`} element={<Coin />} key={coin.id}>
          <CoinItem coin={coin} index={indexOfFirstCoin + index + 1} />
        </Link>
      ))}
      <div className="pagination">
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => handlePageClick(number)}
            className={`page-button ${currentPage === number ? "active" : ""}`}
          >
            {number}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CoinList;
