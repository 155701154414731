import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Coins from "./coins/coins.js";
import Coin from "./coins/coin_description/coin_description.js";
import Navbar from "../components/header/navbar.js";
import Footer from "../components/footer/footer.js";
import Classic from "./trade/classic/classic";
import CoinList from "./coins/coin_list/coin_list.js";
import ComingSoon from "../components/coming_soon/coming_soon.js";

function PageSwitch() {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      {/* Header component */}
      <Navbar />
      <Routes>
        <Route path="/" element={<Coins />} />
        <Route path="/market" element={<CoinList />} />
        <Route path="/classic" element={<Classic />} />
        <Route path="/advanced" element={<ComingSoon />} />
        <Route path="/coin/:coinId" element={<Coin />} />
      </Routes>
      <Footer />
    </>
  );
}

export default PageSwitch;
