import React from "react";
import "./trade_anytime.css";
import trade_anytime from "../../assets/images/coins_images/trade_anytime/trade_anytime.webp";
import appgallery from "../../assets/images/coins_images/trade_anytime/appgallery-button.webp";
import { useTranslation } from "react-i18next";

const TradeAnytime = () => {
  const { t } = useTranslation();

  return (
    <div className="trade-anytime-container">
      <div className="trade-anytime-content">
        <h1>{t("trade_anytime_anywhere")}</h1>
        <ul>
          <li>
            <div className="check-icon">✔</div>
            <div>
              <h3>{t("lightning_trading_performance")}</h3>
              <p>
                {t(
                  "Provides a variety of transaction types and quick order speed"
                )}
              </p>
            </div>
          </li>
          <li>
            <div className="check-icon">✔</div>
            <div>
              <h3>{t("custom_trading_panel")}</h3>
              <p>{t("freely_define_trading_layout")}</p>
            </div>
          </li>
          <li>
            <div className="check-icon">✔</div>
            <div>
              <h3>{t("easy_asset_switching")}</h3>
              <p>{t("real_time_asset_overview")}</p>
            </div>
          </li>
        </ul>
        <div className="app-gallery">
          <img src={appgallery} alt="AppGallery" />
        </div>
      </div>
      <div className="trade-anytime-image">
        <img src={trade_anytime} alt="Trade Anytime Illustration" />
      </div>
    </div>
  );
};

export default TradeAnytime;
