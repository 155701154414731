import React from "react";
import "./touch.css";
import { useTranslation } from "react-i18next";
import img1 from "../../assets/images/coins_images/touch_images/t1.webp";
import img2 from "../../assets/images/coins_images/touch_images/t2.webp";
import img3 from "../../assets/images/coins_images/touch_images/t3.webp";

const Touch = () => {
  const { t } = useTranslation();

  return (
    <div className="touch-container">
      <h2 className="touch-title">{t("get_in_touch")}</h2>
      <div className="touch-images">
        <div className="touch-item">
          <img
            src={img1}
            alt="Trading fees starting from 0"
            className="touch-image"
          />
          <p className="touch-short-text">{t("affordable_rates")}</p>
          <div className="touch-text">
            <p className="gray-text">{t("multi_terminals")}</p>
          </div>
        </div>
        <div className="touch-item">
          <img
            src={img2}
            alt="Rich trading currencies"
            className="touch-image"
          />
          <p className="touch-short-text">{t("wide_selection")}</p>
          <div className="touch-text">
            <p className="gray-text">{t("support_transaction")}</p>
          </div>
        </div>
        <div className="touch-item">
          <img
            src={img3}
            alt="First-Class Arabic Support Team"
            className="touch-image"
          />
          <p className="touch-short-text">{t("top_notch_support")}</p>
          <div className="touch-text">
            <p className="gray-text">{t("arabic_team")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Touch;
