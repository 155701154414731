import React from "react";
import OrderBook from "../../../components/trading_view_order_book/trading_view_order_book";
const Classic = () => {
  return (
    <div style={{ width: "100%"}}>
      <OrderBook />
    </div>
  );
};

export default Classic;
