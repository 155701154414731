import React from "react";
import "./invite_friends.css";
import invite_friends from "../../assets/images/coins_images/invite_friends/invite_friends.webp";
import { useTranslation } from "react-i18next";

const InviteFriends = () => {
  const { t } = useTranslation();

  return (
    <div className="invite-friends-container">
      <div className="invite-friends-image">
        <img src={invite_friends} alt="invite_friends" />
      </div>
      <div className="invite-friends-text">
        <h1>{t("invite_friends")}</h1>
        <h2>{t("enjoy_rewards")}</h2>
        <p>{t("rewarding_transparent_instant_redemption")}</p>
      </div>
    </div>
  );
};

export default InviteFriends;
