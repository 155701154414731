/* eslint-disable jsx-a11y/img-redundant-alt */
// src/components/picture_guide/PictureGuide.js

import React from "react";
import "./guide.css";
import { useTranslation } from "react-i18next";

import im1 from "../../assets/images/coins_images/guide_image/img1.webp";
import img2 from "../../assets/images/coins_images/guide_image/img2.png";
import img3 from "../../assets/images/coins_images/guide_image/img3.png";

const PictureGuide = () => {
  const { t } = useTranslation();
  return (
    <div className="picture-guide-container">
      <h2 className="picture-guide__title">{t("beginners_guide")}</h2>
      <p className="picture-guide__intro">{t("learn_about_trading")}</p>
      <div className="picture-guide">
        <div className="picture-guide__item">
          <img src={im1} alt="GSBbackground" className="picture-guide__image" />
          <p className="picture-guide__text">
            {t("quickly_understand_fiat_transactions")}
          </p>
        </div>
        <div className="picture-guide__item">
          <img
            src={img2}
            alt="Description of the second image"
            className="picture-guide__image"
          />
          <p className="picture-guide__text">
            {t("how_to_buy_digital_currency")}
          </p>
        </div>
        <div className="picture-guide__item">
          <img
            src={img3}
            alt="Description of the third image"
            className="picture-guide__image"
          />
          <p className="picture-guide__text">
            {t("how_to_sell_numerical_currency")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PictureGuide;
