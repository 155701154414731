import React from "react";
import "./footer.css";
import { useTranslation } from "react-i18next";
import GCBicon from "../../assets/images/coins_images/GCB_icon.png";
import trustpilot from "../../assets/images/coins_images/footer/trustpilot.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <Link to={"/"}>
            <img
              src={GCBicon}
              alt="GCB"
              className="footer-image medium-image"
            />
          </Link>
        </div>
        <div className="footer-social">
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-facebook"></i>
          </a>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-twitter"></i>
          </a>
          <div className="trustpilot">
            <img
              src={trustpilot}
              alt="Trustpilot Rating"
              className="footer-image small-image"
            />
          </div>
          <p>{t("trustpilot")}</p>
          <br></br>
          <p style={{ color: "gray" }}>© 2024 GCB</p>
        </div>
        <div className="footer-links">
          <h2>{t("about_us")}</h2>
          <br></br>
          <Link to={"/"} style={{ color: "gray" }}>
            {t("user_agreement")}
          </Link>
          <br></br>
          <Link to={"/"} style={{ color: "gray" }}>
            {t("privacy_policy")}
          </Link>
        </div>
        <div className="footer-links">
          <h2>{t("tools")}</h2>
          <br></br>
          <Link to={"/"} style={{ color: "gray" }}>
            {t("apply_for_listing")}
          </Link>
          <br></br>
          <Link to={"/"} style={{ color: "gray" }}>
            {t("disclaimer")}
          </Link>
        </div>
        <div className="footer-links">
          <h2>{t("our_service")}</h2>
          <br></br>
          <Link to={"/"} style={{ color: "gray" }}>
            {t("ido")}
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
