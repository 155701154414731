import React from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import GCBicon from "../../assets/images/coins_images/GCB_icon.png";
import p1 from "../../assets/images/coins_images/p1.png"; // First image
import p2 from "../../assets/images/coins_images/p2.png"; // Second image
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../assets/languages/language_switcher";

function Navbar() {
  const { t } = useTranslation();
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark sticky-top"
      style={{ backgroundColor: "#000000" }}
    >
      <div className="container-fluid">
        <Link to={"/"} className="navbar-brand">
          <img src={GCBicon} alt="GCB" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to={"/market"} className="nav-link active">
                {t("market")}
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to={"#"}
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("trade")}
              </Link>
              <ul
                className="dropdown-menu dropdown-menu-dark"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <Link className="dropdown-item" to={"/classic"}>
                    <img
                      src={p1}
                      alt="Classic Icon"
                      className="dropdown-icon"
                    />
                    <span className="content">
                      <span>{t("classic")}</span>
                      <span className="description">
                        {t("Simple and easy_to_use interface")}
                      </span>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={"/advanced"}>
                    <img
                      src={p2}
                      alt="Advanced Icon"
                      className="dropdown-icon"
                    />
                    <span className="content">
                      <span>{t("advanced")}</span>
                      <span className="description">
                        {t("Full access to all trading view")}
                      </span>
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item language-switcher">
              <LanguageSwitcher />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
