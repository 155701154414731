import React from "react";
import img1 from "../../assets/images/coins_images/explore_image/ex1.png";
import img2 from "../../assets/images/coins_images/explore_image/ex2.webp";
import img3 from "../../assets/images/coins_images/explore_image/ex3.png";
import "./explore.css";
import { useTranslation } from "react-i18next";

function Explore() {
  const { t } = useTranslation();

  return (
    <div className="app-container">
      <h1>{t("explore_endless_possibilities")}</h1>
      <div className="cards-container">
        <div className="info-card">
          <h2>{t("gcb_academy")}</h2>
          <p>{t("gcb_academy_desc")}</p>
          <img src={img1} alt={t("gcb_academy")} />
        </div>
        <div className="info-card">
          <h2>{t("gcpay")}</h2>
          <p>{t("gcpay_desc")}</p>
          <img src={img2} alt={t("gcpay")} />
        </div>
        <div className="info-card">
          <h2>{t("exchange_earn")}</h2>
          <p>{t("exchange_earn_desc")}</p>
          <img src={img3} alt={t("exchange_earn")} className="special-img" />
        </div>
      </div>
    </div>
  );
}

export default Explore;
