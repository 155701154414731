import React, { useEffect, useState, useCallback } from "react";
import "./trading_view_order_book.css";
import TradingViewWidget from "../trading_view_widget/trading_view_widget";
import { FaExclamationCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { HiOutlineCreditCard } from "react-icons/hi";
import ProgressSteps from "../progress_bar/progress_bar";
import styled from "styled-components";
import orderBook from "../../assets/images/coins_images/order_book/orderBook.PNG";
import buyOrder from "../../assets/images/coins_images/order_book/buyOrder.PNG";
import sellOrder from "../../assets/images/coins_images/order_book/sellOrder.PNG";
import Tooltip from "@mui/material/Tooltip";

const OrderBookContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  font-size: 12px;
`;

const OrderBookHeader = styled.h2`
  text-align: center;
  font-size: 20px;
`;

const OrderBookTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  height: 50px;
  overflow-y: "auto";
`;

const OrderBookHead = styled.thead`
  color: #909090;
  font-family: Arial, sans-serif;
`;

const OrderBookRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const OrderBookCell = styled.td`
  padding: 8px;
  text-align: right;
`;

const OrderBookCellRed = styled.td`
  padding: 8px;
  text-align: right;
  color: red;
`;

const OrderBookCellGreen = styled.td`
  padding: 8px;
  text-align: right;
  color: green;
`;

const OrderBook = () => {
  const [bids, setBids] = useState([]);
  const [asks, setAsks] = useState([]);
  const [showBids, setShowBids] = useState(false);
  const [showAsks, setShowAsks] = useState(false);
  const [showBoth, setShowBoth] = useState(true);
  const [amount, setAmount] = useState("");
  const [symbol, setSymbol] = useState("btcusdt@depth"); // Default symbol
  const [percentage, setPercentage] = useState({ bids: "0%", asks: "0%" }); // Initialize with a default value
  const { t } = useTranslation();

  useEffect(() => {
    const totalAsks = asks.reduce(
      (total, ask) => total + parseFloat(ask.amount),
      0
    );
    const totalBids = bids.reduce(
      (total, bid) => total + parseFloat(bid.amount),
      0
    );
    const totalVolume = totalAsks + totalBids;
    const newPercentage = {
      bids:
        totalVolume > 0
          ? ((totalBids / totalVolume) * 100).toFixed(2) + "%"
          : "0%",
      asks:
        totalVolume > 0
          ? ((totalAsks / totalVolume) * 100).toFixed(2) + "%"
          : "0%",
    };

    setPercentage(newPercentage);
  }, [bids, asks]);

  useEffect(() => {
    const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${symbol}`);

    ws.onopen = () => {
      console.log("WebSocket connection established");
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const formatData = (items) =>
        items
          .map((item) => ({
            price: parseFloat(item[0]).toFixed(3),
            amount: parseFloat(item[1]).toFixed(2),
            total: (item[0] * item[1]).toFixed(2),
          }))
          .filter((item) => item.total > 0 && parseFloat(item.amount) > 0);
      setBids(formatData(data.b));
      setAsks(formatData(data.a));
      setAmount(data.s);
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = (event) => {
      console.log("WebSocket connection closed:", event);
    };

    return () => {
      ws.close();
    };
  }, [symbol]);

  // Callback to handle symbol changes from TradingViewWidget
  const handleSymbolChange = useCallback((newSymbol) => {
    // Convert symbol format if needed (e.g., 'BINANCE:BTCUSDT' to 'btcusdt@depth')
    const formattedSymbol =
      newSymbol.toLowerCase().replace("binance:", "") + "@depth";
    setSymbol(formattedSymbol);
  }, []);

  const removeUSDT = (symbol) => {
    // Check if the symbol starts with "BINANCE:" and ends with "USDT"
    if (symbol.endsWith("USDT")) {
      // Remove "BINANCE:" prefix and "USDT" suffix
      return symbol.slice(0, -4); // "BINANCE:" is 8 characters long and "USDT" is 4 characters long
    }
    return symbol;
  };

  const currentStep = 0;

  const OrderBookIcon = ({ orderBook, t, action }) => {
    return (
      <Tooltip title={t} arrow>
        <img src={orderBook} alt={t} className="order_icons" onClick={action} />
      </Tooltip>
    );
  };

  return (
    <div className="trading_view_cont" style={{ display: "flex" }}>
      <OrderBookContainer>
        <OrderBookHeader>{t("orderbook")}</OrderBookHeader>
        <div class="depth_view_setting">
          <div class="switch_btn">
            <OrderBookIcon
              orderBook={orderBook}
              t={t("orderbook")}
              action={() => {
                setShowBoth(true);
                setShowAsks(false);
                setShowBids(false);
              }}
            />
            <OrderBookIcon
              orderBook={buyOrder}
              t={t("buy_order")}
              action={() => {
                setShowBoth(false);
                setShowAsks(true);
                setShowBids(false);
              }}
            />
            <OrderBookIcon
              orderBook={sellOrder}
              t={t("sell_order")}
              action={() => {
                setShowBoth(false);
                setShowAsks(false);
                setShowBids(true);
              }}
            />
          </div>
        </div>
        <span className="order-book-percentage">
          B: {percentage.bids} S: {percentage.asks}
        </span>
        <div>
          {(showBoth || showBids) && (
            <OrderBookTable>
              <OrderBookHead>
                <OrderBookRow>
                  <OrderBookCell>{t("price")} (USDT)</OrderBookCell>
                  <OrderBookCell>
                    {t("amount")} ({removeUSDT(amount)})
                  </OrderBookCell>
                  <OrderBookCell>{t("total")}</OrderBookCell>
                </OrderBookRow>
              </OrderBookHead>
              <tbody>
                {bids.map((bid, index) => (
                  <OrderBookRow key={index}>
                    <OrderBookCellRed>{bid.price}</OrderBookCellRed>
                    <OrderBookCell>{bid.amount}</OrderBookCell>
                    <OrderBookCell>{bid.total}</OrderBookCell>
                  </OrderBookRow>
                ))}
              </tbody>
            </OrderBookTable>
          )}
        </div>
        <div>
          {(showBoth || showAsks) && (
            <OrderBookTable>
              <OrderBookHead>
                <OrderBookRow>
                  <OrderBookCell>{t("price")} (USDT)</OrderBookCell>
                  <OrderBookCell>
                    {t("amount")} ({removeUSDT(amount)})
                  </OrderBookCell>
                  <OrderBookCell>{t("total")}</OrderBookCell>
                </OrderBookRow>
              </OrderBookHead>
              <tbody>
                {asks.map((ask, index) => (
                  <OrderBookRow key={index}>
                    <OrderBookCellGreen>{ask.price}</OrderBookCellGreen>
                    <OrderBookCell>{ask.amount}</OrderBookCell>
                    <OrderBookCell>{ask.total}</OrderBookCell>
                  </OrderBookRow>
                ))}
              </tbody>
            </OrderBookTable>
          )}
        </div>
      </OrderBookContainer>
      <div className="trading_view_container">
        <div>
          <TradingViewWidget onSymbolChange={handleSymbolChange} />
        </div>
        <div className="transaction">
          <div className="trade_wrap">
            <div className="trade_type">
              <div className="common_nav_tab">
                <ul className="c_list_nav">
                  <li className="c_list_nav_item">
                    <span>{t("spot")}</span>
                  </li>
                </ul>
                <div className="active-line main-1-bg"></div>
              </div>
            </div>

            <div className="trade_exTrade">
              <div className="trade_way">
                <div className="common_nav_tab">
                  <div className="tab_wrap">
                    <ul className="c_list_nav">
                      <li className="c_list_nav_item">
                        <span>{t("limit")}</span>
                      </li>
                      <li className="c_list_nav_item  active-block">
                        <span>{t("market")}</span>
                      </li>
                      <li className="c_list_nav_item">
                        <span>{t("spot_limit")}</span>
                      </li>
                      <li className="c_list_nav_item">
                        <FaExclamationCircle />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="exTrade_form">
                <div className="exTrade_block">
                  <div className="form_block">
                    <div className="top_mag">
                      <span className="exTrade_type">{t("buy")}</span>
                      <span className="lov">
                        <HiOutlineCreditCard size={25} />
                        <span className="lov_Text">-- USDT</span>
                      </span>
                    </div>
                    <div className="trade_input">
                      <div className="input_item">
                        <div className="tit"> {t("price")}</div>
                        <input
                          placeholder=""
                          type="text"
                          className="inputs"
                          disabled
                        />
                        <span class="units">{t("best_market_price")}</span>
                      </div>
                      <div className="input_item">
                        <div className="tit"> {t("total")}</div>
                        <input
                          placeholder=""
                          type="text"
                          className="inputs"
                          disabled
                        />
                        <span class="units"></span>
                      </div>
                    </div>
                    <div className="progress_div">
                      <ProgressSteps currentStep={currentStep} />
                    </div>
                    <div className="max_trade">
                      <span className="max_trade_fend_box">
                        <span class="num_text">{t("max_buy")}</span>
                        <span class="text_cl"></span>
                      </span>
                    </div>
                    <div className="login_or_reg">{t("coming_soon")}</div>
                  </div>
                </div>
                <div className="exTrade_block">
                  <div className="form_block">
                    <div className="top_mag">
                      <span className="exTrade_type">{t("sell")}</span>
                      <span className="lov">
                        <HiOutlineCreditCard size={25} />
                        <span className="lov_Text">-- USDT</span>
                      </span>
                    </div>
                    <div className="trade_input">
                      <div className="input_item">
                        <div className="tit"> {t("price")}</div>
                        <input
                          placeholder=""
                          type="text"
                          className="inputs"
                          disabled
                        />
                        <span class="units">{t("best_market_price")}</span>
                      </div>
                      <div className="input_item">
                        <div className="tit"> {t("total")}</div>
                        <input
                          placeholder=""
                          type="text"
                          className="inputs"
                          disabled
                        />
                        <span class="units"></span>
                      </div>
                    </div>
                    <div className="progress_div">
                      <ProgressSteps currentStep={currentStep} />
                    </div>
                    <div className="max_trade">
                      <span className="max_trade_fend_box">
                        <span class="num_text">{t("max_sell")}</span>
                        <span class="text_cl"></span>
                      </span>
                    </div>
                    <div className="login_or_reg">{t("coming_soon")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderBook;
