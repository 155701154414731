import React from "react";
import "./picture_card.css";
import trade from "../../assets/images/coins_images/picture_card/trade.png";
import invite from "../../assets/images/coins_images/picture_card/invite.png";
import p2p from "../../assets/images/coins_images/picture_card/p2p.png";
import access from "../../assets/images/coins_images/picture_card/access.png";

const PictureCard = () => {
  return (
    <div className="picture-card-container">
      <div className="picture-card">
        <img src={trade} alt="Trade Leverage ETFs" />
      </div>
      <div className="picture-card">
        <img src={invite} alt="Invite Friends" />
      </div>
      <div className="picture-card">
        <img src={p2p} alt="P2P" />
      </div>
      <div className="picture-card">
        <img src={access} alt="Get Early Access" />
      </div>
    </div>
  );
};

export default PictureCard;
