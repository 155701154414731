import React from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import "./progress_bar.css";

const ProgressSteps = ({ currentStep }) => {
  return (
    <ProgressBar
      percent={(currentStep / 3) * 100}
      filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
    >
      <Step>
        {({ accomplished }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          ></div>
        )}
      </Step>
      <Step>
        {({ accomplished }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          ></div>
        )}
      </Step>
      <Step>
        {({ accomplished }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          ></div>
        )}
      </Step>
      <Step>
        {({ accomplished }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          ></div>
        )}
      </Step>
      <Step>
        {({ accomplished }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          ></div>
        )}
      </Step>
    </ProgressBar>
  );
};

export default ProgressSteps;
